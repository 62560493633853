import { apiAdminInstance } from "../Api/ApiInstance";
import GeneralData from "../data/GeneralData.js";

const URL = `${GeneralData.host_api}api/official-site/`;
const API_KEY = GeneralData.host_api_key;

export const CreateGallery = async (gallery, id_moto, selectedImage) => {
  let { descripcion, url_foto_peque, url_foto_grande, orden } = gallery;

  const formDataImage = new FormData();
  formDataImage.append("gallery", selectedImage.smallimage);
  formDataImage.append("gallery", selectedImage.hugeimage);

  let requestOptions = {
    method: "POST",
    body: formDataImage,
    redirect: "follow",
    headers: {
      Authorization: API_KEY,
      user: "Bearer " + localStorage.getItem("SESSION_ID"),
    },
  };

  let res = await fetch(`${URL}gallery/fotos/`, requestOptions);
  res = await res.json();

  url_foto_peque = res[0].path;
  url_foto_grande = res[1].path;

  return await apiAdminInstance.post("api/official-site/gallery/", {
    id_moto,
    descripcion,
    url_foto_peque,
    url_foto_grande,
    orden,
  });
};

export const UpdateGallery = async (gallery, selectedImage) => {
  let { id, id_moto, descripcion, url_foto_peque, url_foto_grande, orden } =
    gallery;

  if (selectedImage.smallimage != null || selectedImage.hugeimage != null) {
    const formDataImage = new FormData();
    formDataImage.append("gallery", selectedImage.smallimage);
    formDataImage.append("gallery", selectedImage.hugeimage);

    let requestOptions = {
      method: "POST",
      body: formDataImage,
      redirect: "follow",
      headers: {
        Authorization: API_KEY,
        user: "Bearer " + localStorage.getItem("SESSION_ID"),
      },
    };

    let res = await fetch(`${URL}/gallery/fotos/`, requestOptions);
    res = await res.json();

    if (selectedImage.smallimage != null) {
      url_foto_peque = res[0].path;
    }
    if (selectedImage.hugeimage != null) {
      url_foto_grande = res[1].path;
    }
  }

  return await apiAdminInstance.post("api/official-site/gallery/update/", {
    id,
    id_moto,
    descripcion,
    url_foto_peque,
    url_foto_grande,
    orden,
  });
};

export const DeleteGallery = async ({ rowData }) => {
  const id = rowData.id;
  // apiAdminInstance.post("api/official-site/gallery/fotos-delete/", rowData);
  return await apiAdminInstance.delete(`api/official-site/gallery/${id}/`);
};
