import React, { useState, useEffect, useContext, useCallback } from "react";
import { CounterContext } from "../../../contexts/CounterContext";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilter,
  faList,
  faGrip,
  faAngleDown,
} from "@fortawesome/free-solid-svg-icons";
import { FiltrarMotos } from "../FiltrarMotos";
import { CardMotos } from "../CardMotos";
import { ModalComparar } from "../../molecules/ModalComparar";
import { SubMenuNavCat } from "../SubMenuNavCat";
import { GetFilterMotos } from "../../../utils/AllMotos";
import clsx from "clsx";
import "../../../helpers/ScrollNavCat";
import "./style.css";
import SkeletonMotoCard from "../SkeletonMotoCard";

export const NavBarCatalogo = () => {
  const { estilosBG, preciominBG, preciomaxBG, transmisionBG } =
    useContext(CounterContext); // Datos desde la busqueda guiada.
  const [ListasMotos, setListasMotos] = useState([]); //para almacenar la información de las motos
  const [totalMotos, setTotalMotos] = useState(0);
  const [isFetching, setIsFetching] = useState(false);

  const [limit] = useState(12);
  const [offset, setOffset] = useState(0);
  let history = useHistory();
  let url = history.location.pathname;

  const [menus, setMenus] = useState({
    submenuordenar: false,
    menufiltrar: "c-modal-1",
    gridstate: true,
    liststate: false,
    nameorden: "Precio más bajo",
    angleicon: faAngleDown,
    load: false,
  });

  const [characteristics, setCharacteristics] = useState({
    keyword: "''",
    id_marca: "''",
    id_estilo: "''",
    precio_min: "''",
    precio_max: "''",
    cilindraje_min: "''",
    cilindraje_max: "''",
    id_transmision: "''",
    id_arranque: "''",
    potencia_min: "''",
    potencia_max: "''",
    torque_min: "''",
    torque_max: "''",
    freno_del: "''",
    freno_tra: "''",
    orden: "precio:asc",
  });

  let {
    keyword,
    id_marca,
    id_estilo,
    precio_min,
    precio_max,
    cilindraje_min,
    cilindraje_max,
    id_transmision,
    id_arranque,
    potencia_min,
    potencia_max,
    torque_min,
    torque_max,
    freno_del,
    freno_tra,
    orden,
  } = characteristics;

  // Consulta al backend
  const consulta = async () => {
    if (url === "/catalogo-de-motos/" || url === "/catalogo-de-motos/BG/list") {
      GetFilterMotos(
        keyword,
        id_marca,
        preciominBG,
        preciomaxBG,
        estilosBG,
        cilindraje_min,
        cilindraje_max,
        transmisionBG,
        id_arranque,
        potencia_min,
        potencia_max,
        torque_min,
        torque_max,
        freno_del,
        freno_tra,
        orden,
        limit,
        offset
      ).then((res) => {
        setListasMotos((prevList) => [...prevList, ...res.data?.listMotos]);
        setTotalMotos(res.data.count);
        setMenus((prevState) => ({
          ...prevState,
          load: true,
        }));
      });
    } else {
      GetFilterMotos(
        keyword,
        id_marca,
        precio_min,
        precio_max,
        id_estilo,
        cilindraje_min,
        cilindraje_max,
        id_transmision,
        id_arranque,
        potencia_min,
        potencia_max,
        torque_min,
        torque_max,
        freno_del,
        freno_tra,
        orden,
        limit,
        offset
      ).then((res) => {
        setListasMotos((prevList) => [...prevList, ...res.data?.listMotos]);
        setTotalMotos(res.data.count);
        setMenus((prevState) => ({
          ...prevState,
          load: true,
        }));
      });
    }
  };

  useEffect(() => {
    // Solo se ejecuta la consulta cuando el componente se monta por primera vez
    const fetchData = async () => {
      if (!isFetching) {
        setIsFetching(true);
        await consulta(); // Realiza la consulta de datos
        await setIsFetching(false); // Finaliza la consulta
      }
    };

    // Realiza la primera consulta al cargar el componente
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Maneja el evento de scroll para cargar más motos cuando se llega al final de la página
  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.offsetHeight - 50
    ) {
      // Solo se incrementa el offset si no estamos en un proceso de fetching
      if (!isFetching && ListasMotos.length < totalMotos) {
        setIsFetching(true);
        setOffset((prevOffset) => prevOffset + limit);
      }
    }
  }, [ListasMotos.length, totalMotos, isFetching, limit]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    const fetchDataOnScroll = async () => {
      setIsFetching(true);
      await consulta(); // Realiza la consulta con el nuevo offset
      setIsFetching(false); // Finaliza la consulta
    };

    // Llamamos a la consulta cada vez que `offset` cambie
    if (offset > 0) {
      fetchDataOnScroll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset]);

  const FiltrarPrecioMayor = () => {
    // setIsFilter(true);
    orden = "precio:desc";
    setMenus((prevState) => ({
      ...prevState,
      nameorden: "Precio más alto",
    }));
    setCharacteristics((prevState) => ({
      ...prevState,
      orden: "precio:desc",
    }));
    consulta();
  };

  const FiltrarPrecioMenor = () => {
    // setIsFilter(true);
    orden = "precio:asc";
    setMenus((prevState) => ({
      ...prevState,
      nameorden: "Precio más bajo",
    }));
    setCharacteristics((prevState) => ({
      ...prevState,
      orden: "precio:asc",
    }));
    consulta();
  };

  const FiltrarCilindrajeMayor = () => {
    // setIsFilter(true);
    orden = "cilindraje:desc";
    setMenus((prevState) => ({
      ...prevState,
      nameorden: "cilindraje más alto",
    }));
    setCharacteristics((prevState) => ({
      ...prevState,
      orden: "cilindraje:desc",
    }));
    consulta();
  };

  const FiltrarCilindrajeMenor = () => {
    // setIsFilter(true);
    orden = "cilindraje:asc";
    setMenus((prevState) => ({
      ...prevState,
      nameorden: "cilindraje más bajo",
    }));
    setCharacteristics((prevState) => ({
      ...prevState,
      orden: "cilindraje:asc",
    }));
    consulta();
  };

  const handleMenuFiltrarOpen = () => {
    setMenus((prevState) => ({
      ...prevState,
      menufiltrar: "c-modal-1 open",
    }));
    document.getElementById("barranav").classList.add("op");
    document.getElementById("CatMotos").classList.add("op");
    document.getElementById("NBC").classList.add("op");
    document.getElementById("NBC2").classList.add("op");
  };

  const handleChangeList = () => {
    setMenus((prevState) => ({
      ...prevState,
      gridstate: false,
      liststate: true,
    }));
  };

  const handleChangeGrid = () => {
    setMenus((prevState) => ({
      ...prevState,
      gridstate: true,
      liststate: false,
    }));
  };

  return menus.load ? (
    <>
      <div className="c-header-catalogo" id="header-catalogo">
        <div className="g-containerNBC" id="NBC">
          <nav className="c-nav-5">
            <div className="c-nav-5__col">
              <span className="c-nav-5__btn" onClick={handleMenuFiltrarOpen}>
                <FontAwesomeIcon
                  className="c-nav-5__btn-icon"
                  icon={faFilter}
                />
                Filtrar motos
              </span>
              <ul className="c-nav-5__list">
                <li>
                  <div
                    className={clsx("list-order", menus.gridstate && "active")}
                    onClick={handleChangeGrid}
                    id="grid"
                  >
                    <FontAwesomeIcon
                      className="c-nav-5__btn-icon"
                      icon={faGrip}
                    />
                  </div>
                </li>
                <li>
                  <div
                    className={clsx("list-order", menus.liststate && "active")}
                    onClick={handleChangeList}
                    id="list"
                  >
                    <FontAwesomeIcon
                      className="c-nav-5__btn-icon"
                      icon={faList}
                    />
                  </div>
                </li>
              </ul>
            </div>

            <div className="c-nav-5__col">
              <SubMenuNavCat
                menus={menus}
                setMenus={setMenus}
                FiltrarPrecioMayor={FiltrarPrecioMayor}
                FiltrarPrecioMenor={FiltrarPrecioMenor}
                FiltrarCilindrajeMayor={FiltrarCilindrajeMayor}
                FiltrarCilindrajeMenor={FiltrarCilindrajeMenor}
                classes={[
                  {
                    class1: "c-nav-5__menu-order1",
                    class2: "c-nav-5__btn--v2",
                    class3: "c-nav-1__submenu--v2",
                    class4: "submenu-ordenarpor",
                  },
                ]}
              />
            </div>
          </nav>
        </div>
      </div>
      <div className="g-containerNBC" id="NBC">
        <div className="c-nav-5__box">
          <SubMenuNavCat
            menus={menus}
            setMenus={setMenus}
            FiltrarPrecioMayor={FiltrarPrecioMayor}
            FiltrarPrecioMenor={FiltrarPrecioMenor}
            FiltrarCilindrajeMayor={FiltrarCilindrajeMayor}
            FiltrarCilindrajeMenor={FiltrarCilindrajeMenor}
            classes={[
              {
                class1: "c-nav-5__menu-order2",
                class2: "c-nav-5__btn--v3",
                class3: "c-nav-1__submenu--v3",
                class4: "submenu-ordenarpor2",
              },
            ]}
          />
        </div>
      </div>
      <div className="g-containerNBC" id="NBC2">
        <FiltrarMotos
          menus={menus}
          setMenus={setMenus}
          setListasMotos={setListasMotos}
          characteristics={characteristics}
          setCharacteristics={setCharacteristics}
        />
        <div className="g-containerNBC1" id="NBC">
          <div id="lista-catalogo">
            <div
              className={clsx("c-list-4", menus.liststate && "LIST3")}
              id="lista4"
            >
              <div className={clsx("jscroll-inner", menus.liststate && "LIST")}>
                <CardMotos LIST={menus.liststate} ListasMotos={ListasMotos} />
              </div>
              {isFetching && (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {Array.from({ length: 3 }, (_, index) => (
                    <SkeletonMotoCard key={index} />
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
        <ModalComparar />
      </div>
    </>
  ) : (
    <div className="menu-mobile-overlay">
      <div className="loader-popup">
        <div className="loader__box">
          <div className="icon-spinner9 animate-spin"></div>
        </div>
      </div>
    </div>
  );
};
