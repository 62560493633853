const GeneralData = {
  publicKey: process.env.REACT_APP_PUBLIC_KEY,
  host_api: process.env.REACT_APP_URL_API,
  host_api_key: "Bearer " + process.env.REACT_APP_URL_API_KEY,
  domain: process.env.REACT_APP_URL_DOMAIN,
  host: process.env.REACT_APP_HOST_SITE,
  from: process.env.REACT_APP_EMAIL_USER_FROM,
  politictUpdatedAt: process.env.REACT_APP_POLITIC_UPDATE_AT,
};

module.exports = GeneralData;
