import data from "../../../data/GeneralData";

const collectionAllies = [
  {
    alliesId: "1",
    name: "davivienda",
    cuentas: [
      {
        id: "1",
        urlimg: data.host + "images/logos/davivienda-logo-horizontal.jpg",
        numcuenta: "038469997563",
        tipocuenta: "Corriente",
      },
    ],
  },
  {
    alliesId: "2",
    name: "bancolombia",
    cuentas: [
      {
        id: "2",
        urlimg: data.host + "images/logos/bancolombia.jpg",
        numcuenta: "16600000605",
        tipocuenta: "Ahorros (sucursal no bancaria)",
      },
    ],
  },
  {
    alliesId: "3",
    name: "efecty",
    cuentas: [
      {
        id: "3",
        urlimg: data.host + "images/logos/efecty.jpg",
        numcuenta: "11237",
        tipocuenta: "",
      },
    ],
  },
  {
    alliesId: "5",
    name: "Convenio Surtimax",
    cuentas: [
      {
        id: "5",
        urlimg: data.host + "images/logos/surtimax.jpeg",
        numcuenta: "11237",
        tipocuenta: "",
      },
    ],
  },
  {
    alliesId: "6",
    name: "Convenio Mayorista",
    cuentas: [
      {
        id: "6",
        urlimg: data.host + "images/logos/mayorista.jpeg",
        numcuenta: "11237",
        tipocuenta: "",
      },
    ],
  },
  {
    alliesId: "7",
    name: "Convenio Punto Red",
    cuentas: [
      {
        id: "7",
        urlimg: data.host + "images/logos/puntored.jpeg",
        numcuenta: "11237",
        tipocuenta: "",
      },
    ],
  },
  {
    alliesId: "8",
    name: "Convenio Supergiros",
    cuentas: [
      {
        id: "8",
        urlimg: data.host + "images/logos/supergiros.jpeg",
        numcuenta: "11237",
        tipocuenta: "",
      },
    ],
  },
  {
    alliesId: "9",
    name: "Convenio Grupo Exito",
    cuentas: [
      {
        id: "9",
        urlimg: data.host + "images/logos/grupoexito.jpeg",
        numcuenta: "11237",
        tipocuenta: "",
      },
    ],
  },
  {
    alliesId: "10",
    name: "Convenio Punto Pago",
    cuentas: [
      {
        id: "10",
        urlimg: data.host + "images/logos/puntopago.jpeg",
        numcuenta: "11237",
        tipocuenta: "",
      },
    ],
  },
  {
    alliesId: "11",
    name: "AvalPay",
    cuentas: [
      {
        id: "11",
        urlimg: data.host + "images/logos/aval-pay.png",
        numcuenta: "AvapPay",
        tipocuenta: "Pago en linea",
      },
    ],
  },
  {
    alliesId: "12",
    name: "record",
    cuentas: [
      {
        id: "12",
        urlimg: data.host + "images/logos/record.jpg",
        numcuenta: "Suprecredito S.A.S.",
        tipocuenta: "Convenio",
      },
    ],
  },
];

export default collectionAllies;
