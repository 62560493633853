import "./style.css";

const SkeletonMotoCard = () => {
  return (
    <div className="skeleton-card">
      <div className="skeleton-card__icons">
        <div className="skeleton-icon"></div>
        <div className="skeleton-icon"></div>
      </div>
      <div className="skeleton-card__content">
        <div className="skeleton-card__image"></div>
        <div className="skeleton-card__details">
          <div className="skeleton-card__header">
            <div className="skeleton-card__title">
              <div className="skeleton-text skeleton-text--large"></div>
              <div className="skeleton-text skeleton-text--medium"></div>
            </div>
            <div className="skeleton-card__icon"></div>
          </div>
          <p className="skeleton-text skeleton-text--small"></p>
          <div className="skeleton-card__footer">
            <div className="skeleton-color"></div>
            <div className="skeleton-price"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkeletonMotoCard;
